import { Component, Vue, Prop } from 'vue-property-decorator'

import { ProcessService } from '@/services/processService'

import { Process } from '@/store/modules/process/process';
import { IconListOptions } from '@/components/wrappers/molds/IconListItem';
import EContent from '@/components/esic/eContent/EContent.vue';

//wrappers
import {
	WColumns,
	WColumn,
	wIcon,
	Arrow,
	IconList,
	SizedBox
} from '@/components/wrappers';

@Component({
	components: {
		WColumns,
		WColumn,
		wIcon,
		Arrow,
		IconList,
		EContent,
		SizedBox
	}
})
export default class ProcessPage extends Vue {

	processService = new ProcessService(this.$store)

	get process(): Process {
		return this.processService.convertDateProcess(this.$store.getters["process/process"])
	}

	mounted() {
		console.log(this.$route.params.id);
		const id = this.$route.params.id;
		this.getProcessById(id)
	}

	getProcessById(id: any){
		this.processService.dispatchProcess(id)
	}

	iconListInto: IconListOptions = {
		class: 'theme2-c is-size-5 has-text-centered',
		IconListItens: [
			{
				icon: 'mdi mdi-file-replace',
				title:'Apensar'
			},
			{
				icon: 'mdi mdi-file-refresh',
				title:'Desapensar'
			},
			{
				icon: 'mdi mdi-message-text',
				title:'Comentar'
			},
			{
				icon: 'mdi mdi-share',
				title: 'Encaminhar',
			},
			{
				icon: 'mdi mdi-file-cabinet',
				title:'Arquivar'
			},
			{
				icon: 'mdi mdi-eye',
				title:'Acompanhar'
			},
			{
				icon: 'mdi mdi-printer',
				title:'Imprimir'
			},
			{
				icon: 'mdi mdi-tag-text',
				title:'Imprimir no formato de etiqueta'
			},
			
		]
	}

}

