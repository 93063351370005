import { Alert } from '@/services/notification';
import { WolfApiData } from '@/components/wrappers/molds/TableOptions';
import { Process } from '@/store/modules/process/process';
import { convertDatetimePtBr, isEmpty, getYearDatetimePtBr } from '@/utils';

export class ProcessService {
	store: any
	alert: Alert
  totalItensProcess: any = 0

	constructor(store: any){
		this.store = store
		this.alert = new Alert(this.store)
	}

	async dispatchSearchProcess(query: string): Promise<any> {
		await this.store.dispatch('process/searchProcess', query)
		
		if(this.store.getters['process/error']){
			this.alert.apiMessage("Não foi possível pesquisar processos.", this.store.getters['process/error'], "danger")
			return false
		}else{
			return this.convertDateAndStatus(this.store.getters['process/wolfApiProcess'])
		}
	}

	async dispatchProcess(idProcess: string): Promise<any> {
		await this.store.dispatch('process/fetchMyProcess', idProcess)
		
		if(this.store.getters['process/error']){
			this.alert.apiMessage("Não foi possível carregar processo.", this.store.getters['process/error'], "danger")
			return false
		}else{
			return this.convertDateHistory(this.store.getters['process/process'])
		}
	}

	setProcess(process: Process): void {
		this.store.dispatch('process/setProcess', process)
	}

  async dispatchGetMyGroups(): Promise<any> {
		await this.store.dispatch('group/fetchMyGroups')
		
		if(this.store.getters['group/error']){
			this.alert.apiMessage("Não foi possível carregar o grupo.", this.store.getters['process/error'], "danger")
			return false
		}else{
			return this.store.getters['group/myGroups']
		}
	}

  async dispatchGetGroupProcess(idGroup: string): Promise<any> {
		await this.store.dispatch('process/groupProcess', idGroup)
    // console.log(this.store.getters['process/wolfApiProcess']);
    
    this.totalItensProcess = this.store.getters['process/wolfApiProcess'].totalitems
		if(this.store.getters['process/error']){
			this.alert.apiMessage("Error: ", this.store.getters['process/error'], "danger")
			return false
		}else{
			return this.convertDateAndStatus(this.store.getters['process/wolfApiProcess'])
		}
	}

  async dispatchReceiveProcess(idGroup: string, idProcess: string, data: object): Promise<any> {
    const receber = async () => {
      const operation = {
        idGroup,
        idProcess,
        data
      }
      await this.store.dispatch('process/receiveProcess', operation)
      if(this.store.getters['process/error']){
        this.alert.apiMessage("", this.store.getters['process/error'], "danger")
        return false
      }
      else{
        this.dispatchGetGroupProcess(idGroup)
        this.dispatchProcess(idProcess);
        this.alert.defaultSuccess()
      }
    }
    this.alert.dialogConfirm('Deseja continuar com esta operação?', receber)
  }

  async dispatchGenericOperationProcess(idGroup: string, idProcess: string, data: object, urlOperation: string): Promise<any> {
    const onConfirm = async () => {
      const operation = {
        idGroup,
        idProcess,
        data,
        urlOperation: urlOperation
      }
      await this.store.dispatch('process/genericOprationProcess', operation)
      if(this.store.getters['process/error']){
        this.alert.apiMessage("", this.store.getters['process/error'], "danger")
        return false
      }
      else{
        this.dispatchGetGroupProcess(idGroup)
        if(urlOperation == 'forward'){
          this.store.commit('process/setProcess', {})
        }
        else{
          this.dispatchProcess(idProcess);
        }
        this.alert.notification('is-success', 'Processo Recebido')
      }
    }
    this.alert.dialogConfirm('Deseja continuar com esta operação?', onConfirm)
  }

  getTotalProcess(){
    return this.totalItensProcess;
  }

	get getProcess(): WolfApiData {
		return this.store.getters['process/wolfApiProcess']
	}

	convertDateAndStatus(wolfApiProcess: WolfApiData): WolfApiData {
		const list: Process[] = []
		for (let i = 0; i < wolfApiProcess.list.length; i++) {
			list.push(this.convertDateProcess(wolfApiProcess.list[i]))
		}
		wolfApiProcess.list = list;
		return wolfApiProcess
	}

	convertDateHistory(process: Process): Process {
		if(process && process?.history){
			for (let j = 0; j < process.history.length; j++) {
				process.history[j].datetime = convertDatetimePtBr(process.history[j].datetime)
			}
		}
		return process
	}

	convertDateProcess(process: Process): Process {
		process.dateTimeOpened = convertDatetimePtBr(process.dateTimeOpened)
		process.numYear = `${process.idProcess}/${getYearDatetimePtBr(process.dateTimeOpened)}`
		process.status = this.getStatusName(process.status)
		return process
	}

	convertNumYear(input: any){
		if(!isEmpty(input.numYear)){
			const numYear = input.numYear.split("/");
			if(numYear.length == 2){
				input["num"] = numYear[0]
				input["year"] = numYear[1]
			}else{
				this.alert.warning("Campo <b>Número/Ano</b> escrito de forma incorreta.")
			}
		}
		input["numYear"] = ""
		return input
	}

	getStatusName(status: string): string {
		switch (status) {
			case "0":
				return 'Desativado'
			case "1":
				return `<span class="has-text-warning-dark is-size-7"> <span class="icon"> <i class="mdi mdi-file-cabinet"></i>
        </span> Arquivado </span>`
			case "2":
				return `<span class="has-text-success is-size-7"><span class="icon"> <i class="fas fa-envelope-open"></i>
        </span> Recebido </span>`
			case "3":
				return `<span class="has-text-danger is-size-7"><span class="icon"> <i class="fas fa-envelope"></i>
        </span> Não Recebido </span>`
			case "4":
				return '<span class="has-text-warning-dark"> Retornado </span>'
			case "5":
				return `<span class="has-text-success-dark is-size-7"> 
        <span class="icon"> <i class="fas fa-check"></i>
      </span> Solucionado </span>`
			case "6":
				return '<span class="has-text-success-dark">  Finalizado </span>'
			case "7":
				return 'A importar'
			default:
				return status
		}
	}
}